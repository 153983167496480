export const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);

  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};
export const hoursToDay = (hours: number) => {
  if (hours < 24) {
    return `${hours} hour(s)`;
  } else {
    const days = Math.floor(hours / 24);
    const remainingHours = hours % 24;
    return `${days} day(s)${
      remainingHours > 0 ? ` ${remainingHours} hour(s)` : ""
    }`;
  }
};
